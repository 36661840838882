import {mapActions, mapGetters, mapMutations} from "vuex";
import {VueSlideToggle} from "vue-slide-toggle";
export default {
  name: "accordeon",
  props: {
    data: {
      type: Object,
      default: ()=> {}
    }
  },
  components:{
    VueSlideToggle
  },
  data(){
    return{
      open: false,
    }
  },
  created() {
  },
  computed:{
  },

  methods:{
  }
}